<template>
  <div v-if="!updating">
    <div
      class="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-px-2 tw-py-2 tw-rounded-md hover:tw-bg-gray-200 dark:hover:tw-bg-gray-700 tw-transition tw-duration-300"
    >
      <div class="tw-truncate">
        <UiTitle small :title="title" />
        <UiSubtitle class="tw-ml-1" small :subtitle="JSON.stringify(value)" />
      </div>
      <v-btn v-if="askBeforeDelete !== index" @click.stop="askBeforeDelete = index" class="tw-ml-auto" icon>
        <v-icon color="#ff6464">
          {{ icons.mdiDelete }}
        </v-icon>
      </v-btn>
      <div class="tw-ml-auto tw-flex tw-items-center" v-else>
        <v-btn @click.stop="removeJSONBlock(index)" icon>
          <v-icon color="success">
            {{ icons.mdiCheckboxMarkedCircle }}
          </v-icon>
        </v-btn>
        <v-btn @click.stop="askBeforeDelete = null" icon>
          <v-icon>{{ icons.mdiCloseCircle }}</v-icon>
        </v-btn>
      </div>
    </div>
    <v-divider class="tw-mx-2 tw-my-2" />
  </div>
  <div v-else>
    <v-skeleton-loader type="image" width="100%" height="50px" class="tw-my-2" />
  </div>
</template>

<script>
import { mdiCloseCircle, mdiCheckboxMarkedCircle, mdiDelete } from '@mdi/js'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'BrandsReviewsBlock',
  props: {
    title: {
      type: String,
      required: true,
    },
    updating: {
      type: Boolean,
      required: true,
    },
    value: {
      type: [String, Object],
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  components: {
    UiTitle,
    UiSubtitle,
  },
  data() {
    return {
      icons: {
        mdiCloseCircle,
        mdiCheckboxMarkedCircle,
        mdiDelete,
      },
      askBeforeDelete: null,
    }
  },
  methods: {
    removeJSONBlock(index) {
      this.askBeforeDelete = null
      this.$emit('remove', index)
    },
  },
}
</script>

<style></style>
