import { encodeImageFileAsURL } from '@/utils/utilities.util'

const uploadFile = {
  methods: {
    onFileChange(e, saveProperty, locale = null) {
      if (locale) {
        this[saveProperty][locale].file = e.target.files[0] || e.dataTransfer.files[0]
        encodeImageFileAsURL(this[saveProperty][locale].file, base64 => (this[saveProperty][locale].base64 = base64))
      } else {
        this[saveProperty].file = e.target.files[0] || e.dataTransfer.files[0]
        encodeImageFileAsURL(this[saveProperty].file, base64 => (this[saveProperty].base64 = base64))
      }
    },
    resetCurrentFile(file, locale = null) {
      if (locale) {
        this[file][locale] = {
          file: null,
          base64: '',
        }
      } else {
        this[file] = {
          file: null,
          base64: '',
        }
      }
    },
  },
}

export default uploadFile
