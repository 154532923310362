<template>
  <div class="ui-actions" :class="{ 'theme--dark': $vuetify.theme.dark, 'ui-actions--full': !drawer }">
    <div
      class="ui-actions__container"
      :class="{
        'ui-actions__container--centered': centered,
        'ui-actions__container--large': large,
        'ui-actions__container--small': small,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UiActions',
  props: {
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      drawer: state => state.backoffice.drawer,
    }),
  },
}
</script>

<style lang="scss">
.ui-actions {
  @apply tw-p-4 sm:tw-px-6;

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-top: 1px solid map-deep-get($material-light, 'banner', 'border');
  background: map-get($material-light, 'banner', 'background');

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    left: 256px;
  }

  &--full {
    @media (min-width: map-get($grid-breakpoints, 'md')) {
      left: 0;
    }
  }

  &.theme--dark {
    border-top: 1px solid rgba(map-deep-get($material-light, 'banner', 'border'), 0.12);
    background: map-get($material-dark, 'banner', 'background');
  }

  &__container {
    @apply tw-flex tw-items-center tw-justify-end tw-m-auto tw-gap-4;

    &--centered {
      @apply tw-justify-center;
    }

    &--large {
      @apply tw-max-w-3xl;
    }

    &--small {
      @apply tw-max-w-xl;
    }
  }
}
</style>
