export const botLocales = Object.freeze([
  {
    code: 'en',
    text: 'English',
    key: 'en',
    label: {
      fr: 'Anglais',
      en: 'English',
    },
  },
  {
    code: 'fr',
    text: 'Français',
    key: 'fr',
    label: {
      fr: 'Français',
      en: 'French',
    },
  },
])

export const botVersions = Object.freeze(['v1', 'v3'])
