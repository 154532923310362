<template>
  <div
    class="ui-title"
    :class="{
      'ui-title--large': large,
      'ui-title--default': !large && !small,
      'ui-title--small': small,
      'ui-title--centered': centered,
    }"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'UiTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-title {
  &--large {
    @apply tw-font-bold tw-text-2xl tw-leading-7;
  }

  &--default {
    @apply tw-font-medium tw-text-xl tw-leading-6;
  }

  &--small {
    @apply tw-font-medium tw-text-base tw-leading-5;
  }

  &--centered {
    @apply tw-text-center;
  }
}
</style>
