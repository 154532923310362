var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-container",class:{
    'ui-container--with-actions': _vm.withActions,
    'ui-container--no-padding': _vm.noPadding,
  }},[_c('div',{staticClass:"ui-container__container",class:{
      'ui-container__container--large': _vm.large,
      'ui-container__container--small': _vm.small,
    }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }