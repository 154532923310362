<template>
  <v-card flat class="ui-file-input" :disabled="uploading">
    <div
      class="ui-file-input__overlay"
      :class="{ 'ui-file-input__overlay--dragging': dragging }"
      @dragover.prevent="dragover"
      @dragleave.prevent="dragleave"
      @drop.prevent="drop"
      @click.stop="$refs.file.click()"
    ></div>
    <v-icon size="52">{{ icon }}</v-icon>
    <input type="file" @change="onChange" ref="file" :accept="computedFileFormats" class="ui-file-input__file" />
    <UiSubtitle class="ui-file-input__caption" :subtitle="$t('ui.fileInput.drop')" v-if="dragging" />
    <UiSubtitle class="ui-file-input__caption" :subtitle="$t('ui.fileInput.selectOrDrop')" v-else-if="!file" />
    <UiSubtitle class="ui-file-input__caption" :subtitle="`${file.name} (${formatBytes(file.size)})`" v-else />
  </v-card>
</template>

<script>
import { mdiCloudUploadOutline } from '@mdi/js'
import { formatBytes } from '@/utils/helper.util'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'UiFileInput',
  components: {
    UiSubtitle,
  },
  props: {
    file: {
      type: File,
      required: false,
      default: null,
    },
    fileFormats: {
      type: Array,
      required: false,
      default: () => ['.'],
    },
    icon: {
      type: String,
      required: false,
      default: mdiCloudUploadOutline,
    },
    uploading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    dragging: false,
    formatBytes,
  }),
  computed: {
    computedFileFormats() {
      return this.fileFormats.map(f => (f !== '.' ? `.${f}` : f)).join(',')
    },
  },
  methods: {
    dragover() {
      this.dragging = true
    },
    dragleave() {
      this.dragging = false
    },
    drop(e) {
      this.dragging = false
      this.$emit('change', e.dataTransfer.files[0])
    },
    onChange(e) {
      if (e.target.files.length) {
        this.$emit('change', e.target.files[0])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-file-input {
  @apply tw-relative tw-w-full tw-p-4 sm:tw-p-6 tw-h-36 tw-mt-6 tw-flex tw-flex-col tw-justify-center tw-items-center tw-overflow-hidden;
  @apply tw-rounded-2xl #{!important};

  &__overlay {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-border-2 tw-border-dashed tw-cursor-pointer tw-border-gray-200 dark:tw-border-opacity-10 tw-rounded-2xl;

    z-index: 1;

    &--dragging {
      @apply tw-border-solid tw-border-primary;
      @apply tw-bg-primary tw-bg-opacity-10;
    }
  }

  &__file {
    display: none;
  }

  &__caption {
    @apply tw-text-center tw-mt-2;
  }
}
</style>
