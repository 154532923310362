import { mapActions } from 'vuex'

const ManageMediaMixin = {
  methods: {
    ...mapActions({
      uploadMedia: 'tool/uploadMedia',
      removeMedia: 'tool/removeMedia',
    }),
    async manageMedia({ target, media, payloadNewMedia, payloadMedia, mediaName, mediaId }) {
      if (media && payloadNewMedia) {
        await this.removeMedia(media)
        return await this.uploadMedia({
          target: target,
          file: payloadNewMedia,
          mediaName,
          mediaId,
        })
      } else if (!media && payloadNewMedia) {
        return await this.uploadMedia({
          target: target,
          file: payloadNewMedia,
          mediaName,
          mediaId,
        })
      } else if (media && !payloadMedia) {
        await this.removeMedia(media, mediaId)
        return ''
      }
      return null
    },
  },
}

export default ManageMediaMixin
