<template>
  <div class="brand-reviews">
    <UiContainer large with-actions>
      <div class="brand-reviews__selector" :class="{ 'theme--dark': $vuetify.theme.dark }">
        <div class="brand-reviews__selector__title">
          <UiTitle :title="$t('brands.id.reviews.title')" />
          <UiSubtitle :subtitle="$t('brands.id.reviews.langSelect', { lang: `${currentLang.label[$i18n.locale]}` })" />
        </div>
        <v-autocomplete
          v-model="currentLang"
          id="brandLang"
          solo
          flat
          dense
          outlined
          return-object
          hide-details
          item-text="text"
          :prepend-inner-icon="icons.mdiTranslate"
          :items="botLocales"
          @change="currentJsonKey = ''"
        />
      </div>
      <v-divider class="tw-my-6" />
      <form class="brand-reviews__form" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <UiTitle :title="$t('brands.id.reviews.brand.title')" />
        <section class="brand-reviews__form__section">
          <ui-upload-file
            :label="$t('brands.id.reviews.brand.backgroundImage.label')"
            :small="true"
            :current-image="brandBackgroundImage[currentLang.key]"
            :image="brandModel.review_page.background_picture[currentLang.key]"
            :avatar="brandModel.name"
            @onFileChange="onFileChange($event, 'brandBackgroundImage', currentLang.key)"
            @resetClientLogo="resetCurrentFile('brandBackgroundImage', currentLang.key)"
            @deleteLogoFile="
              () =>
                (brandModel.review_page.background_picture[currentLang.key] = '') &&
                resetCurrentFile('brandBackgroundImage', currentLang.key)
            "
          />
          <div class="brand-reviews__form__section__grid">
            <ui-block id="brandLogoLabel" :label="$t('brands.id.reviews.brand.logo.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="brandModel.review_page.logo_url[currentLang.key]"
                  id="brandLogo"
                  solo
                  flat
                  outlined
                  :placeholder="$t('brands.id.reviews.brand.logo.placeholder')"
                  :prepend-inner-icon="icons.mdiLinkVariant"
                />
              </template>
            </ui-block>
            <ui-block id="brandPageTitleLabel" :label="$t('brands.id.reviews.brand.baseline.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="brandModel.review_page.page_title[currentLang.key]"
                  id="brandPageTitle"
                  solo
                  flat
                  outlined
                  :placeholder="$t('brands.id.reviews.brand.baseline.placeholder')"
                  :prepend-inner-icon="icons.mdiFormTextbox"
                />
              </template>
            </ui-block>
            <ui-block id="brandMetaTitleLabel" :label="$t('brands.id.reviews.brand.metaTitle.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="brandModel.review_page.meta_title[currentLang.key]"
                  id="brandMetaTitle"
                  solo
                  flat
                  outlined
                  :placeholder="$t('brands.id.reviews.brand.metaTitle.placeholder')"
                  :prepend-inner-icon="icons.mdiTagSearch"
                />
              </template>
            </ui-block>
            <ui-block id="brandMetaDescriptionLabel" :label="$t('brands.id.reviews.brand.metaDescription.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="brandModel.review_page.meta_description[currentLang.key]"
                  id="brandMetaDescription"
                  solo
                  flat
                  outlined
                  :placeholder="$t('brands.id.reviews.brand.metaDescription.placeholder')"
                  :prepend-inner-icon="icons.mdiTextBoxSearch"
                />
              </template>
            </ui-block>
            <ui-block id="brandDescriptionLabel" :label="$t('brands.id.reviews.brand.description.label')">
              <template v-slot:body>
                <v-textarea
                  v-model="brandModel.review_page.description[currentLang.key]"
                  id="brandDescription"
                  solo
                  flat
                  outlined
                  auto-grow
                  no-resize
                  :placeholder="$t('brands.id.reviews.brand.description.placeholder')"
                  :prepend-inner-icon="icons.mdiTextBox"
                />
              </template>
            </ui-block>
          </div>
          <v-divider class="tw-mt-2 tw-mb-6" />
          <section class="brand-reviews__form__section">
            <UiTitle :title="$t('brands.id.reviews.json.title')" />
            <brand-reviews-json
              v-for="(jsonField, index) in jsonFieldsBrand"
              :key="`card ${index}`"
              :index="index"
              :json-field="jsonField"
              :current-json-field="currentJsonField"
              :jsonBlockUpdated="jsonBlockUpdated"
              :current-json-key="currentJsonKey"
              @createJsonBlock="createJsonBlock"
              @editJSONField="editJSONField"
              @updateJsonField="updateJsonField"
              @removeJsonBlock="removeJsonBlock"
              @removeJsonField="removeJsonField"
            />
          </section>
        </section>
        <v-divider class="tw-my-6" />
        <section class="brand-reviews__form__section">
          <UiTitle :title="$t('brands.id.reviews.location.title')" />
          <UiSubtitle :subtitle="$t('brands.id.reviews.location.subtitle')" />
          <ui-upload-file
            class="tw-mt-6"
            :label="$t('brands.id.reviews.location.logo.label')"
            :small="true"
            :current-image="locationLogo[currentLang.key]"
            :image="brandModel.review_page.default_shop_picture[currentLang.key]"
            :avatar="brandModel.name"
            @onFileChange="onFileChange($event, 'locationLogo', currentLang.key)"
            @resetClientLogo="resetCurrentFile('locationLogo', currentLang.key)"
            @deleteLogoFile="
              () =>
                (brandModel.review_page.default_shop_picture[currentLang.key] = '') &&
                resetCurrentFile('locationLogo', currentLang.key)
            "
          />
          <div class="brand-reviews__form__section__grid">
            <ui-block id="locationPageTitleLabel" :label="$t('brands.id.reviews.location.baseline.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="brandModel.review_page.location_page_title[currentLang.key]"
                  id="locationPageTitle"
                  solo
                  flat
                  outlined
                  :placeholder="$t('brands.id.reviews.location.baseline.placeholder')"
                  :prepend-inner-icon="icons.mdiFormTextbox"
                />
              </template>
            </ui-block>
            <ui-block id="locationMetaTitleLabel" :label="$t('brands.id.reviews.location.metaTitle.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="brandModel.review_page.location_meta_title[currentLang.key]"
                  id="locationMetaTitle"
                  solo
                  flat
                  outlined
                  :placeholder="$t('brands.id.reviews.location.metaTitle.placeholder')"
                  :prepend-inner-icon="icons.mdiTagSearch"
                />
              </template>
            </ui-block>
            <ui-block id="locationMetaDescriptionLabel" :label="$t('brands.id.reviews.location.metaDescription.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="brandModel.review_page.location_meta_description[currentLang.key]"
                  id="locationMetaDescription"
                  solo
                  flat
                  outlined
                  :placeholder="$t('brands.id.reviews.location.metaDescription.placeholder')"
                  :prepend-inner-icon="icons.mdiTextBoxSearch"
                />
              </template>
            </ui-block>
            <ui-block id="locationTitleSeoLabel" :label="$t('brands.id.reviews.location.titleSeo.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="brandModel.review_page.seo_title[currentLang.key]"
                  id="locationTitleSeo"
                  solo
                  flat
                  outlined
                  :placeholder="$t('brands.id.reviews.location.titleSeo.placeholder')"
                  :prepend-inner-icon="icons.mdiTag"
                />
              </template>
            </ui-block>
            <ui-block id="locationDescriptionSeoLabel" :label="$t('brands.id.reviews.location.descriptionSeo.label')">
              <template v-slot:body>
                <v-textarea
                  v-model="brandModel.review_page.location_description[currentLang.key]"
                  id="locationDescriptionSeo"
                  solo
                  flat
                  outlined
                  auto-grow
                  no-resize
                  :placeholder="$t('brands.id.reviews.location.descriptionSeo.placeholder')"
                  :prepend-inner-icon="icons.mdiTextBox"
                />
              </template>
            </ui-block>
          </div>
          <v-divider class="tw-mt-2 tw-mb-6" />
          <section class="brand-reviews__form__section">
            <UiTitle :title="$t('brands.id.reviews.json.title')" />
            <brand-reviews-json
              v-for="(jsonField, index) in jsonFieldsLocation"
              :key="`card ${index}`"
              :index="index"
              :json-field="jsonField"
              :current-json-field="currentJsonField"
              :jsonBlockUpdated="jsonBlockUpdated"
              :current-json-key="currentJsonKey"
              @createJsonBlock="createJsonBlock"
              @editJSONField="editJSONField"
              @updateJsonField="updateJsonField"
              @removeJsonBlock="removeJsonBlock"
              @removeJsonField="removeJsonField"
            />
          </section>
        </section>
        <UiActions large centered>
          <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
            {{ $t('button.save') }}
          </v-btn>
        </UiActions>
      </form>
    </UiContainer>
  </div>
</template>

<script>
import merge from 'lodash/merge'
import {
  mdiTranslate,
  mdiLinkVariant,
  mdiFormTextbox,
  mdiTagSearch,
  mdiTextBoxSearch,
  mdiTag,
  mdiTextBox,
} from '@mdi/js'
import { botLocales } from '@/config/botStaticList.config'
import { returnObjectByKeyAndValue, generateRandomString } from '@/utils/utilities.util'
import { validationMixin } from 'vuelidate'
import uploadFileMixin from '@/mixins/uploadFile.mixin'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiBlock from '@/components/UI/Block.vue'
import UiUploadFile from '@/components/UI/UploadFile.vue'
import BrandReviewsJson from '@/components/brands/reviews/Json.vue'

const initializeAvailabeLangArray = () => ({ fr: [], en: [] })
const initializeAvailabeLang = () => ({ fr: '', en: '' })
const initializeMedia = () => ({ fr: mediaData(), en: mediaData() })
const mediaData = () => ({ file: null, base64: '' })

export default {
  name: 'BrandReviews',
  props: {
    currentBrand: {
      type: Object,
      required: true,
    },
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin, uploadFileMixin],
  components: {
    UiUploadFile,
    UiActions,
    UiContainer,
    UiTitle,
    UiSubtitle,
    UiBlock,
    BrandReviewsJson,
  },
  data() {
    return {
      brandModel: {
        review_page: {
          // BRAND
          background_picture: {
            ...initializeAvailabeLang(),
          },
          logo_url: {
            ...initializeAvailabeLang(),
          },
          meta_description: {
            ...initializeAvailabeLang(),
          },
          description: {
            ...initializeAvailabeLang(),
          },
          page_title: {
            ...initializeAvailabeLang(),
          },
          meta_title: {
            ...initializeAvailabeLang(),
          },
          footer: {
            ...initializeAvailabeLangArray(),
          },
          footer_social: {
            ...initializeAvailabeLangArray(),
          },
          header: {
            ...initializeAvailabeLangArray(),
          },
          hot_pictures: {
            ...initializeAvailabeLangArray(),
          },
          theme: {
            ...initializeAvailabeLangArray(),
          },
          // Location
          default_shop_picture: {
            ...initializeAvailabeLang(),
          },
          location_page_title: {
            ...initializeAvailabeLang(),
          },
          location_meta_title: {
            ...initializeAvailabeLang(),
          },
          location_meta_description: {
            ...initializeAvailabeLang(),
          },
          seo_title: {
            ...initializeAvailabeLang(),
          },
          location_description: {
            ...initializeAvailabeLang(),
          },
          location_hot_pictures: {
            ...initializeAvailabeLangArray(),
          },
        },
      },
      brandBackgroundImage: {
        ...initializeMedia(),
      },
      locationLogo: {
        ...initializeMedia(),
      },
      botLocales,
      icons: {
        mdiTranslate,
        mdiLinkVariant,
        mdiFormTextbox,
        mdiTagSearch,
        mdiTextBoxSearch,
        mdiTag,
        mdiTextBox,
      },
      currentLang: { id: null, key: '', label: {} },
      jsonBlockUpdated: false,
      currentJsonKey: '',
    }
  },
  mounted() {
    this.currentLang = botLocales[0]
    this.brandModel = merge(this.brandModel, this.currentBrand)
  },
  computed: {
    currentJsonField() {
      if (this.currentJsonKey) {
        return this.brandModel.review_page[this.currentJsonKey][this.currentLang.key]
      }
      return []
    },
    jsonFieldsBrand() {
      return [
        {
          label: this.$t('brands.id.reviews.brand.json.socialLink.label'),
          placeholder: this.$t('brands.id.reviews.brand.json.socialLink.placeholder'),
          key: 'footer_social',
        },
        {
          label: this.$t('brands.id.reviews.brand.json.footerLink.label'),
          placeholder: this.$t('brands.id.reviews.brand.json.footerLink.placeholder'),
          key: 'footer',
        },
        {
          label: this.$t('brands.id.reviews.brand.json.header.label'),
          placeholder: this.$t('brands.id.reviews.brand.json.header.placeholder'),
          key: 'header',
        },
        {
          label: this.$t('brands.id.reviews.brand.json.hotPictures.label'),
          placeholder: this.$t('brands.id.reviews.brand.json.hotPictures.placeholder'),
          key: 'hot_pictures',
        },
        {
          label: this.$t('brands.id.reviews.brand.json.theme.label'),
          placeholder: this.$t('brands.id.reviews.brand.json.theme.placeholder'),
          key: 'theme',
        },
      ]
    },
    jsonFieldsLocation() {
      return [
        {
          label: this.$t('brands.id.reviews.location.json.locationHotPictures.label'),
          placeholder: this.$t('brands.id.reviews.location.json.locationHotPictures.placeholder'),
          key: 'location_hot_pictures',
        },
      ]
    },
  },
  methods: {
    resetReviewsMedia() {
      this.brandBackgroundImage = {
        ...initializeMedia(),
      }
      this.locationLogo = {
        ...initializeMedia(),
      }
    },
    createJsonBlock({ nestedLvl, currentBlockIndex }) {
      this.jsonBlockUpdated = true
      const currentJson = this.brandModel.review_page[this.currentJsonKey][this.currentLang.key]
      if (nestedLvl === 0) {
        currentJson.push({})
      } else if (nestedLvl === 1) {
        const obj = { ...currentJson[currentBlockIndex], [generateRandomString()]: '' }
        this.$set(currentJson, currentBlockIndex, obj)
      }
      this.jsonBlockUpdated = false
    },
    updateJsonField({ key, oldKey, value, currentBlockIndex }) {
      const currentJson = this.brandModel.review_page[this.currentJsonKey][this.currentLang.key]

      delete currentJson[currentBlockIndex][oldKey]
      currentJson[currentBlockIndex][key] = value
    },
    removeJsonBlock(index) {
      this.brandModel.review_page[this.currentJsonKey][this.currentLang.key].splice(index, 1)
    },
    removeJsonField({ key, currentBlockIndex }) {
      this.jsonBlockUpdated = true
      delete this.brandModel.review_page[this.currentJsonKey][this.currentLang.key][currentBlockIndex][key]
      this.jsonBlockUpdated = false
    },
    editJSONField(jsonKey) {
      this.currentJsonKey = jsonKey
    },
    onSubmit() {
      const brandBackgroundImageByLocale = returnObjectByKeyAndValue(this.brandBackgroundImage, 'base64')
      const locationLogoByLocale = returnObjectByKeyAndValue(this.locationLogo, 'base64')

      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('updateBrand', {
          brand: this.brandModel,
          brandBackgroundImage: brandBackgroundImageByLocale,
          locationLogo: locationLogoByLocale,
        })
        this.$v.$reset()
      }
    },
  },
  validations() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.brand-reviews {
  @apply tw-h-full;

  &__selector {
    @apply tw-flex tw-items-center tw-p-4 tw-bg-gray-200 tw-rounded;
    &.theme--dark {
      @apply tw-bg-gray-700;
    }

    &__title {
      flex: 1 1 100%;
    }
  }

  &__form {
    &__section {
      &:first-child {
        @apply tw-mt-0;
      }

      &__grid {
        @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full tw-mt-6;
      }
    }
  }
}
</style>
