<template>
  <v-card class="brand-reviews-json__card">
    <div
      :key="jsonField.key"
      class="tw-cursor-pointer tw-flex tw-flex-row tw-items-center tw-gap-4"
      @click="editJSONField(jsonField.key)"
    >
      <v-icon>{{ icons.mdiCodeJson }}</v-icon>
      <UiLabel class="tw-cursor-pointer" id="name" :label="jsonField.label" />
      <UiSubtitle :subtitle="jsonField.placeholder" />
    </div>
    <v-expand-transition>
      <div class="brand-reviews-json__card__expand" v-if="editJSON && thisExpandJson(jsonField.key)">
        <div
          @click="goBackToBlock(bread.nestedLvl)"
          v-for="(bread, index) in breadcrumbs"
          :key="`bread ${index}`"
          :class="`tw-ml-${bread.nestedLvl}`"
          class="brand-reviews-json__card__expand__nested"
        >
          <v-icon size="20" color="rgba(59, 130, 246)">{{ icons.mdiArrowRightBottom }}</v-icon>
          <ui-subtitle class="brand-reviews-json__card__expand__nested__subtitle" :subtitle="bread.label" />
        </div>
        <div v-if="nestedLvl <= 1">
          <template v-if="nestedLvl === 0">
            <brand-reviews-block
              class="tw-cursor-pointer"
              v-for="(value, index) in currentJsonField"
              :key="`Block ${index}`"
              :updating="jsonBlockUpdated"
              :value="value instanceof Array ? {} : value"
              :index="index"
              :title="
                $t('brands.id.reviews.json.block.nested.title', {
                  num: index + 1,
                })
              "
              @click.native="openBlock(index, value, null)"
              @remove="removeJsonBlock(index)"
            />
          </template>
          <template v-if="nestedLvl === 1 && !jsonBlockUpdated">
            <brand-reviews-block
              class="tw-cursor-pointer"
              v-for="(value, key) in currentJsonField[currentBlockIndexEdit]"
              :key="`Block ${key}`"
              :updating="jsonBlockUpdated"
              :value="value"
              :title="
                $t('brands.id.reviews.json.block.nested.titleField', {
                  type: key,
                })
              "
              @click.native="openBlock(index, value, key)"
              @remove="removeJsonField(key)"
            />
          </template>
        </div>
        <div class="brand-reviews-json__form__section__grid" v-else>
          <ui-block id="brandCurrentFieldKey" :label="$t('brands.id.reviews.json.block.key.label')">
            <template v-slot:body>
              <v-text-field
                v-model="currentFieldEdit.key"
                id="brandCurrentFieldKey"
                solo
                flat
                outlined
                :placeholder="$t('brands.id.reviews.json.block.key.placeholder')"
                :prepend-inner-icon="icons.mdiKey"
              />
            </template>
          </ui-block>
          <ui-block id="brandCurrentFieldValue" :label="$t('brands.id.reviews.json.block.value.label')">
            <template v-slot:body>
              <v-text-field
                v-model="currentFieldEdit.value"
                id="brandCurrentFieldValue"
                solo
                flat
                outlined
                :placeholder="$t('brands.id.reviews.json.block.value.placeholder')"
                :prepend-inner-icon="icons.mdiTag"
              />
            </template>
          </ui-block>
        </div>
        <v-btn
          v-if="nestedLvl !== 2"
          key="createButton"
          class="tw-mt-6 tw-flex tw-mx-auto"
          color="primary"
          rounded
          outlined
          @click="createJsonBlock()"
        >
          <v-icon dense left> {{ icons.mdiPlus }} </v-icon>
          {{ sentenceByNestedLvl }}
        </v-btn>
        <v-btn
          v-else
          key="updateButton"
          class="tw-mt-6 tw-flex tw-mx-auto"
          color="primary"
          rounded
          outlined
          @click="updateJsonField()"
        >
          <v-icon dense left> {{ icons.mdiUpdate }} </v-icon>
          {{ sentenceByNestedLvl }}
        </v-btn>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mdiCodeJson, mdiKey, mdiTag, mdiArrowRightBottom, mdiPlus, mdiUpdate } from '@mdi/js'
import BrandReviewsBlock from '@/components/brands/reviews/Block.vue'
import UiLabel from '@/components/UI/Label.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiBlock from '@/components/UI/Block.vue'

export default {
  name: 'BrandsReviewsJson',
  props: {
    jsonBlockUpdated: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    jsonField: {
      type: Object,
      required: true,
    },
    currentJsonField: {
      type: Array,
      required: true,
    },
    currentJsonKey: {
      type: String,
      required: true,
    },
  },
  components: {
    UiLabel,
    UiBlock,
    UiSubtitle,
    BrandReviewsBlock,
  },
  data() {
    return {
      icons: {
        mdiCodeJson,
        mdiKey,
        mdiTag,
        mdiArrowRightBottom,
        mdiPlus,
        mdiUpdate,
      },
      editJSON: false,
      currentBlockIndexEdit: null,
      currentFieldEdit: null,
      nestedLvl: 0,
    }
  },
  computed: {
    sentenceByNestedLvl() {
      switch (this.nestedLvl) {
        case 1:
          return this.$t('brands.id.reviews.json.block.nested.create')
        case 2:
          return this.$t('brands.id.reviews.json.block.nested.update')
        default:
          return this.$t('brands.id.reviews.json.block.create')
      }
    },
    breadcrumbs() {
      // NestedLvl 2 = value editable
      if (!this.nestedLvl) {
        return []
      }

      if (this.nestedLvl === 2) {
        return [
          {
            label: this.$t('brands.id.reviews.json.block.title'),
            nestedLvl: 1,
          },
          {
            label: this.$t('brands.id.reviews.json.block.nested.title', {
              num: this.currentFieldEdit.index + 1,
            }),
            nestedLvl: 2,
          },
        ]
      }
      return [
        {
          label: this.$t('brands.id.reviews.json.block.title'),
          nestedLvl: 1,
        },
      ]
    },
  },
  methods: {
    createJsonBlock() {
      this.$emit('createJsonBlock', { nestedLvl: this.nestedLvl, currentBlockIndex: this.currentBlockIndexEdit })
    },
    editJSONField(jsonKey) {
      this.resetJSONValue()
      if (this.thisExpandJson(jsonKey)) {
        this.editJSON = false
        this.$emit('editJSONField', '')
      } else {
        this.editJSON = true
        this.$emit('editJSONField', jsonKey)
      }
    },
    updateJsonField() {
      const { key, oldKey, value } = this.currentFieldEdit
      this.$emit('updateJsonField', { key, oldKey, value, currentBlockIndex: this.currentBlockIndexEdit })
      this.goBackToBlock(2)
    },
    removeJsonBlock(index) {
      this.$emit('removeJsonBlock', index)
    },
    removeJsonField(key) {
      this.$emit('removeJsonField', { key, currentBlockIndex: this.currentBlockIndexEdit })
    },
    openBlock(index, value, key) {
      if (!this.nestedLvl) {
        this.currentFieldEdit = null
        this.nestedLvl = 1
        this.currentBlockIndexEdit = index
        return
      }
      this.nestedLvl = 2
      this.currentFieldEdit = {
        value,
        key,
        oldKey: key,
        index,
      }
    },
    resetJSONValue() {
      this.nestedLvl = 0
      this.currentBlockIndexEdit = null
      this.currentFieldEdit = null
    },
    thisExpandJson(iterateJsonKey) {
      return this.currentJsonKey === iterateJsonKey
    },
    goBackToBlock(nestedLvl) {
      if (nestedLvl === 1) {
        this.nestedLvl = 0
        this.currentBlockIndexEdit = null
      } else if (nestedLvl === 2) {
        this.nestedLvl = 1
        this.currentFieldEdit = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-reviews-json {
  &__card {
    @apply tw-my-2 tw-p-4 tw-shadow;

    &__expand {
      @apply tw-mx-4 tw-my-4;

      &__nested {
        @apply tw-mb-2 tw-cursor-pointer tw-flex tw-items-end;

        &__subtitle {
          @apply tw-text-blue-500;
          font-size: 0.9rem;
        }
      }
    }
  }
}
</style>
