<template>
  <div class="tw-flex tw-justify-center tw-items-center tw-flex-col">
    <input
      class="tw-hidden"
      type="file"
      ref="logoClientInput"
      accept=".png,.jpg,.jpeg,.svg,.gif"
      @change="emitEvent('onFileChange', $event)"
    />
    <v-hover v-slot="{ hover }">
      <v-card :disabled="disabled" :elevation="hover ? 8 : 2">
        <v-img
          :height="small ? '144' : '192'"
          :width="small ? '144' : '192'"
          :lazy-src="currentImage.preview"
          :src="currentImage.base64 ? currentImage.base64 : image"
          alt=""
          v-if="hasLogo"
          contain
        >
          <v-overlay absolute :opacity="0.6" v-if="hover">
            <div class="tw-flex tw-items-center tw-justify-center tw-gap-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon large v-bind="attrs" v-on="on" @click="$refs.logoClientInput.click()">
                    <v-icon> {{ icons.mdiImageEdit }} </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('button.modify') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    large
                    v-bind="attrs"
                    v-on="on"
                    @click="emitEvent('resetClientLogo')"
                    v-if="currentImage.base64"
                  >
                    <v-icon> {{ icons.mdiUndoVariant }} </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('button.cancel') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    large
                    v-bind="attrs"
                    v-on="on"
                    @click="emitEvent('deleteLogoFile')"
                    v-if="!currentImage.base64"
                  >
                    <v-icon> {{ icons.mdiTrashCanOutline }} </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('button.delete') }}</span>
              </v-tooltip>
            </div>
          </v-overlay>
        </v-img>
        <div
          class="tw-flex tw-items-center tw-justify-center"
          :class="small ? 'tw-h-36 tw-w-36' : 'tw-h-48 tw-w-48'"
          v-else
        >
          <v-avatar :size="small ? '112' : '152'" color="primary">
            <span class="tw-text-white tw-text-5xl" v-if="avatar">
              {{ avatar.charAt(0) }}
            </span>
          </v-avatar>
          <v-overlay absolute :opacity="0.6" v-if="hover">
            <div class="tw-flex tw-items-center tw-justify-center tw-gap-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon large v-bind="attrs" v-on="on" @click="$refs.logoClientInput.click()">
                    <v-icon> {{ icons.mdiImagePlus }} </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('button.add') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon large v-bind="attrs" v-on="on" v-if="isUndoActive" @click="emitEvent('resetClientLogo')">
                    <v-icon> {{ icons.mdiUndoVariant }} </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('button.cancel') }}</span>
              </v-tooltip>
            </div>
          </v-overlay>
        </div>
      </v-card>
    </v-hover>
    <UiSubtitle v-if="label" class="tw-my-4" :subtitle="label" large />
  </div>
</template>

<script>
import { mdiImageEdit, mdiImagePlus, mdiUndoVariant, mdiTrashCanOutline } from '@mdi/js'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'UiUploadFile',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    image: {
      type: String,
      required: false,
      default: '',
    },
    currentImage: {
      type: Object,
      required: true,
      default: () => ({ file: null, base64: '' }),
    },
    avatar: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    UiSubtitle,
  },
  data() {
    return {
      icons: {
        mdiUndoVariant,
        mdiImagePlus,
        mdiTrashCanOutline,
        mdiImageEdit,
      },
    }
  },
  computed: {
    hasLogo() {
      return this.currentImage.base64 || this.image
    },
    isUndoActive() {
      return this.image !== '' && this.image !== this.currentImage.preview
    },
  },
  methods: {
    emitEvent(emitName, event = {}) {
      this.$emit(emitName, event)
      if (emitName === 'resetClient') {
        this.$refs.logoClientInput.value = null
      }
    },
  },
}
</script>
