<template>
  <div class="brand-presencemanagement">
    <UiContainer large with-actions>
      <section class="brand-presencemanagement__section">
        <UiTitle :title="$t('brands.id.presencemanagement.title')" />
        <UiSubtitle :subtitle="$t('brands.id.presencemanagement.subtitle')" />
        <a
          class="tw-text-sm"
          target="blank_"
          href="https://www.notion.so/gtrsuite/EVM-format-de-donn-es-attendu-d1b7419431d94dec97c176aad3d2f8b7"
          >{{ $t('brands.id.presencemanagement.link') }}</a
        >
        <div class="tw-flex tw-flex-col tw-mt-6">
          <UiTitle small :title="$t('brands.id.presencemanagement.export.title')" />
          <UiSubtitle :subtitle="$t('brands.id.presencemanagement.export.subtitle')" />
          <v-btn
            class="tw-self-center tw-my-6"
            color="primary"
            rounded
            @click.stop="downloadExportFile"
            :loading="downloading"
            :disabled="downloading"
          >
            <v-icon>{{ icons.mdiApplicationExport }}</v-icon>
            <span class="tw-ml-2">{{ $t('ui.button.export') }}</span>
          </v-btn>
        </div>
        <div class="tw-flex tw-flex-col tw-mt-6">
          <UiTitle small :title="$t('brands.id.presencemanagement.import.title')" />
          <UiSubtitle :subtitle="$t('brands.id.presencemanagement.import.subtitle')" />
          <UiFileInput :file="importFile" :file-formats="fileFormat.text" :uploading="uploading" @change="checkFile" />

          <div class="tw-text-center tw-mt-6">
            <v-btn :disabled="uploadDisabled" :loading="uploading" color="primary" rounded @click="importPM"
              >{{ $t('brands.id.presencemanagement.import.btn') }}
            </v-btn>
          </div>
        </div>
      </section>
    </UiContainer>
  </div>
</template>

<script>
import { mdiApplicationExport } from '@mdi/js'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiFileInput from '@/components/UI/FileInput.vue'
import { fileFormat } from '@/config/fileFormatPresenceManagement.config'
import { mapActions } from 'vuex'

const FILE_MAX_SIZE = 1004857
let initialData = () => ({
  importFile: null,
  errorCode: '',
  errorData: '',
  fileUploaded: false,
  fileValidation: {
    format: false,
    size: false,
  },
})

export default {
  name: 'BrandsPresenceManagement',
  components: {
    UiContainer,
    UiTitle,
    UiSubtitle,
    UiFileInput,
  },
  data: () => ({
    uploading: false,
    downloading: false,
    fileFormat,
    ...initialData(),
    icons: {
      mdiApplicationExport,
    },
  }),
  computed: {
    uploadDisabled() {
      return (
        this.importFile === null ||
        this.uploading ||
        (this.importFile !== null && (!this.fileValidation.format || !this.fileValidation.size))
      )
    },
  },
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
      sendEVMFile: 'presencemanagement/sendEVMFile',
      getExportFile: 'presencemanagement/getExportFile',
    }),

    checkFile(file) {
      this.resetData()
      const extension = file.name.split('.').pop()
      this.fileValidation.format = fileFormat.text.includes(extension)
      this.fileValidation.size = file.size < FILE_MAX_SIZE
      this.importFile = file
    },

    fileSucess(key) {
      return this.importFile && this.fileValidation[key]
    },
    fileError(key) {
      return this.importFile && !this.fileValidation[key]
    },
    dataSuccess(key) {
      return this.importFile && this.fileUploaded && this.dataValidation[key] === 1
    },
    dataError(key) {
      return this.importFile && this.fileUploaded && this.dataValidation[key] === -1
    },

    async importPM() {
      this.uploading = true

      let formData = new FormData()
      formData.append('evmFile', this.importFile)
      formData.append('name', this.importFile.name)

      try {
        console.log(this.importFile)
        await this.sendEVMFile(formData)
        this.fileUploaded = true
        this.uploading = false
        this.success = true
        this.setAlert({
          color: 'success',
          text: this.$t('brands.id.presencemanagement.notification.success'),
        })
        this.resetData()
      } catch ({ data }) {
        this.errorCode = data.error ? data.error.type : null
        this.errorData = data.error ? data.error.message : ''
        switch (this.errorCode) {
          case 'quantity':
            this.fileValidation.quantity = false
            break
          case 'id':
            this.fileValidation.id = false
            break
          default:
            this.setAlert({
              color: 'error',
              text: this.$t('error.notification.default'),
            })
        }
        this.fileUploaded = true
        this.uploading = false
      }
    },

    async downloadExportFile() {
      this.downloading = true
      await this.getExportFile()
      this.downloading = false
    },

    resetData() {
      Object.assign(this.$data, initialData())
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-presencemanagement {
  @apply tw-h-full;

  &__section {
    @apply tw-mt-4;
  }
}
</style>
