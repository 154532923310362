<template>
  <div class="ui-switch__status" :class="{ 'theme--dark': $vuetify.theme.dark }">
    <div class="ui-switch__status__left">
      <v-icon class="ui-switch__status__left__icon" :class="switchStatus ? 'active' : 'inactive'">
        {{ icon }}
      </v-icon>
      <div class="tw-flex tw-flex-col">
        <UiLabel id="switchStatus" :label="label" />
        <UiSubtitle
          :class="switchStatus ? 'active' : 'inactive'"
          :subtitle="switchStatus ? $t('common.status.active') : $t('common.status.inactive')"
        />
      </div>
    </div>
    <div>
      <v-switch
        class="tw-pt-0 tw-mt-0"
        :input-value="switchStatus"
        :disabled="disabled"
        inset
        dense
        flat
        color="primary"
        hide-details
        @change="status => $emit('onChange', status)"
      />
    </div>
  </div>
</template>

<script>
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiLabel from '@/components/UI/Label.vue'

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    switchStatus: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    UiSubtitle,
    UiLabel,
  },
}
</script>

<style scoped lang="scss">
.ui-switch {
  &__status {
    @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-6 tw-py-3 tw-px-4 tw-rounded-lg;
    background-color: $white;

    &.theme--dark {
      background-color: $vuetify-dark-1;
    }

    &__left {
      @apply tw-flex tw-flex-row tw-items-center tw-gap-4 tw-mr-4;

      &__icon {
        @apply tw-rounded-full tw-p-1;

        border: 1px solid;
      }
    }
  }
}

.active {
  color: var(--v-success-base);
}
.inactive {
  color: var(--v-error-base);
}
</style>
