<template>
  <div class="brand-general">
    <UiContainer large with-actions>
      <form class="brand-general__form" @submit.prevent="onSubmit" novalidate autocomplete="off">
        <UiTitle :title="$t('brands.id.general.title')" />
        <section class="brand-general__form__section">
          <div class="tw-flex tw-justify-center tw-my-4 tw-gap-16">
            <ui-upload-file
              label="Logo"
              :small="true"
              :current-image="brandLogo"
              :image="brandModel.logo"
              :avatar="brandModel.name"
              @onFileChange="onFileChange($event, 'brandLogo')"
              @resetClientLogo="resetCurrentFile('brandLogo')"
              @deleteLogoFile="() => (brandModel.logo = '') && resetCurrentFile('brandLogo')"
            />
            <ui-upload-file
              label="Favicon"
              :small="true"
              :current-image="brandFavicon"
              :image="brandModel.favicon"
              :avatar="brandModel.name"
              @onFileChange="onFileChange($event, 'brandFavicon')"
              @resetClientLogo="resetCurrentFile('brandFavicon')"
              @deleteLogoFile="() => (brandModel.favicon = '') && resetCurrentFile('brandFavicon')"
            />
          </div>
          <div class="brand-general__form__section__grid">
            <ui-block id="versionLabel" class="required-asterisk" :label="$t('brands.id.general.version.label')">
              <template v-slot:body>
                <v-autocomplete
                  v-model="brandModel.gmb_mode"
                  id="version"
                  solo
                  flat
                  outlined
                  :placeholder="$t('brands.id.general.version.placeholder')"
                  :prepend-inner-icon="icons.mdiUpdate"
                  :items="botVersions"
                  :error-messages="simpleErrors('brandModel', 'gmb_mode')"
                />
              </template>
            </ui-block>
            <ui-block id="versionLabel" class="required-asterisk" :label="$t('brands.id.general.startFrom.label')">
              <template v-slot:body>
                <v-menu
                  v-model="modalStartFrom"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="brandModel.gmb_import_since"
                      solo
                      flat
                      outlined
                      :prepend-inner-icon="icons.mdiCalendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="simpleErrors('brandModel', 'gmb_import_since')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :max="todayDate"
                    v-model="brandModel.gmb_import_since"
                    @input="modalStartFrom = false"
                  ></v-date-picker>
                </v-menu>
              </template>
            </ui-block>
            <ui-block id="languageLabel" class="required-asterisk" :label="$t('brands.id.general.language.label')">
              <template v-slot:body>
                <v-autocomplete
                  v-model="brandModel.default_locale"
                  id="language"
                  solo
                  flat
                  outlined
                  item-value="code"
                  item-text="text"
                  :placeholder="$t('brands.id.general.language.placeholder')"
                  :prepend-inner-icon="icons.mdiWeb"
                  :items="botLocales"
                  :error-messages="simpleErrors('brandModel', 'default_locale')"
                />
              </template>
            </ui-block>
            <ui-block id="brandActivity" class="required-asterisk" :label="$t('brands.id.general.activity.label')">
              <template v-slot:body>
                <v-autocomplete
                  :value="{ id: brandActivity }"
                  id="activity"
                  solo
                  flat
                  outlined
                  item-value="id"
                  item-text="name"
                  :placeholder="$t('brands.id.general.activity.placeholder')"
                  :prepend-inner-icon="icons.mdiClipboardList"
                  :items="activity"
                  @change="updateActivity"
                  :error-messages="simpleErrors('brandModel', 'activity')"
                />
              </template>
            </ui-block>
            <ui-block
              id="brandGmbCategory"
              class="required-asterisk"
              :label="$t('brands.id.general.gmbCategory.label')"
            >
              <template v-slot:body>
                <v-autocomplete
                  :value="brandCategory"
                  id="gmbCategory"
                  solo
                  flat
                  outlined
                  item-value="id_category"
                  item-text="name"
                  :placeholder="$t('brands.id.general.gmbCategory.placeholder')"
                  :prepend-inner-icon="icons.mdiClipboardList"
                  :items="gmbCategory"
                  @change="updateGmbCategory"
                  :error-messages="simpleErrors('brandModel', 'gmb_category')"
                />
              </template>
            </ui-block>
          </div>
        </section>
        <section>
          <ui-block class="required-asterisk" id="brandActivity" :label="$t('brands.id.general.import.label')">
            <template v-slot:body>
              <UiSubtitle
                small
                :subtitle="$t('brands.id.general.import.default', { count: brandModel.gmb_import_count })"
              />
              <v-slider hide-details v-model="brandModel.gmb_import_count" thumb-label step="5" min="5" max="150" />
            </template>
          </ui-block>
        </section>
        <section class="brand-general__form__section">
          <UiTitle :title="$t('brands.id.general.autoReply.title')" />
          <ui-switch
            :label="$t('brands.id.general.autoReply.status')"
            :switch-status="brandAutoreplyStatus"
            :icon="icons.mdiEmailCheckOutline"
            @onChange="updateAutoReply"
          />
          <ui-switch
            :label="$t('brands.id.general.autoReply.option')"
            :switch-status="brandAutoreplyOptions"
            :icon="icons.mdiEmailSearchOutline"
            @onChange="updateAutoReplyOption"
          />
        </section>
        <UiActions large centered>
          <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
            {{ $t('button.save') }}
          </v-btn>
        </UiActions>
      </form>
    </UiContainer>
  </div>
</template>

<script>
import { mdiEmailCheckOutline, mdiEmailSearchOutline, mdiClipboardList, mdiWeb, mdiUpdate, mdiCalendar } from '@mdi/js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { clone } from '@/utils/utilities.util'
import uploadFileMixin from '@/mixins/uploadFile.mixin'
import ErrorsMixin from '@/mixins/errors.mixin'
import UiActions from '@/components/UI/Actions.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiSwitch from '@/components/UI/Switch.vue'
import UiBlock from '@/components/UI/Block.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiUploadFile from '@/components/UI/UploadFile.vue'

export default {
  name: 'BrandGeneral',
  components: {
    UiActions,
    UiContainer,
    UiSwitch,
    UiBlock,
    UiTitle,
    UiSubtitle,
    UiUploadFile,
  },
  mixins: [validationMixin, ErrorsMixin, uploadFileMixin],
  props: {
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentBrand: {
      type: Object,
      required: true,
    },
    activity: {
      type: Array,
      required: true,
    },
    gmbCategory: {
      type: Array,
      required: true,
    },
    botLocales: {
      type: Array,
      required: true,
    },
    botVersions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiEmailCheckOutline,
      mdiEmailSearchOutline,
      mdiWeb,
      mdiClipboardList,
      mdiUpdate,
      mdiCalendar,
    },
    brandModel: {},
    brandLogo: {
      file: null,
      base64: '',
    },
    brandFavicon: {
      file: null,
      base64: '',
    },
    currentActivity: {},
    modalStartFrom: false,
  }),
  mounted() {
    this.brandModel = clone(this.currentBrand)
  },
  watch: {
    'brandModel.default_locale'() {
      if (this.brandModel.default_locale && this.brandModel.locales) {
        this.brandModel.locales = this.brandModel.locales.filter(locale => this.brandModel.default_locale !== locale)
      }
    },
  },
  computed: {
    todayDate() {
      return new Date(Date.now()).toISOString().substr(0, 10)
    },
    brandAutoreplyStatus() {
      return this.brandModel.autoreply_status === '1' ? true : false
    },
    brandAutoreplyOptions() {
      return this.brandModel.autoreply_option === '1' ? true : false
    },
    brandActivity() {
      return this.brandModel.activity
    },
    brandCategory() {
      return this.brandModel.gmb_category
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('updateBrand', {
          brand: this.brandModel,
          brandLogo: this.brandLogo.base64,
          brandFavicon: this.brandFavicon.base64,
        })
        this.$v.$reset()
      }
    },
    updateAutoReply(status) {
      this.brandModel.autoreply_status = status ? '1' : '0'
    },
    updateAutoReplyOption(status) {
      this.brandModel.autoreply_option = status ? '1' : '0'
    },
    updateActivity(activityId) {
      this.brandModel.activity = activityId
    },
    updateGmbCategory(categoryId) {
      this.brandModel.gmb_category = categoryId
    },
  },
  validations() {
    return {
      brandModel: {
        autoreply_status: {
          required,
        },
        autoreply_option: {
          required,
        },
        activity: {
          required,
        },
        gmb_category: {
          required,
        },
        default_locale: {
          required,
        },
        gmb_import_count: {
          required,
        },
        gmb_mode: {
          required,
        },
        gmb_import_since: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.brand-general {
  @apply tw-h-full;

  &__form {
    &__section {
      @apply tw-mt-6;
      &:first-child {
        @apply tw-mt-0;
      }

      &__grid {
        @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full tw-mt-6;
      }
    }
  }
}
</style>
