<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="brands-id" v-else>
      <UiBanner fluid :title="currentBrand.name" :breadcrumbs="breadcrumbs" />

      <v-tabs v-model="currentTab">
        <v-tab class="tw-normal-case" v-for="(tab, idx) in tabs" :key="idx" :href="`#${tab.key}`">
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <!-- Brand Section -->
      <brands-general
        ref="brandGeneral"
        v-if="currentTab === 'general'"
        :saving="saving"
        :current-brand="currentBrand"
        :activity="activity"
        :gmb-category="gmbCategory"
        :bot-locales="botLocales"
        :bot-versions="botVersions"
        @updateBrand="manageGeneralMediaBeforeSave"
      />
      <!-- Reviews Section -->
      <brands-reviews
        ref="brandReviews"
        v-if="currentTab === 'reviews'"
        :saving="saving"
        :current-brand="currentBrand"
        @updateBrand="manageReviewsMediaBeforeSave"
      />
      <!-- Touchpoint Section -->
      <brands-touchpoint
        ref="brandTouchpoint"
        v-if="currentTab === 'touchpoint'"
        :saving="saving"
        :current-brand="currentBrand"
        :touchpoints="touchpoints"
        @updateTouchpoints="saveTouchpoints"
      />
      <brands-presence-management
        ref="brandPresenceManagement"
        v-if="currentTab === 'presencemanagement'"
        :saving="saving"
        :current-brand="currentBrand"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiMagnify, mdiCheckCircle, mdiCloseCircle } from '@mdi/js'
import ManageMediaMixin from '@/mixins/manageMedia.mixin'
import UiBanner from '@/components/UI/Banner.vue'
import BrandsGeneral from '@/views/Brands/General.vue'
import BrandsReviews from '@/views/Brands/Reviews.vue'
import BrandsTouchpoint from '@/views/Brands/Touchpoint.vue'
import BrandsPresenceManagement from '@/views/Brands/PresenceManagement.vue'
import { botLocales, botVersions } from '@/config/botStaticList.config'

export default {
  name: 'BrandsId',
  components: {
    UiBanner,
    BrandsGeneral,
    BrandsReviews,
    BrandsTouchpoint,
    BrandsPresenceManagement,
  },
  mixins: [ManageMediaMixin],
  data: () => ({
    icons: {
      mdiMagnify,
      mdiCheckCircle,
      mdiCloseCircle,
    },
    saving: false,
    search: '',
    botLocales,
    botVersions,
  }),
  async created() {
    this.setUpdating(true)
    await Promise.all([
      this.getBrand({ brandId: this.$route.params.id }),
      this.getActivity(),
      this.getGmbCategory(),
      this.getTouchpoints({ brandId: this.$route.params.id }),
    ])
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      currentBrand: state => state.brand.currentBrand,
      activity: state => state.brand.activity,
      gmbCategory: state => state.brand.gmbCategory,
      touchpoints: state => state.brand.touchpoints,
      updating: state => state.backoffice.updating,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('brands.list.title'),
          to: {
            name: 'BrandsList',
          },
        },
        {
          text: this.currentBrand.name,
          to: {
            name: 'Brand',
          },
        },
      ]
    },
    tabs() {
      return [
        {
          label: this.$t('clients.id.general.name'),
          key: 'general',
        },
        {
          label: this.$t('brands.id.touchpoint.title'),
          key: 'touchpoint',
        },
        {
          label: this.$t('brands.id.reviews.title'),
          key: 'reviews',
        },
        {
          label: this.$t('brands.id.presencemanagement.title'),
          key: 'presencemanagement',
        },
      ]
    },
    currentTab: {
      get() {
        return this.$route.params.currentTab
      },
      set(tab) {
        return this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
      },
    },
  },
  methods: {
    ...mapActions({
      getBrand: 'brand/getBrand',
      getActivity: 'brand/getActivity',
      getGmbCategory: 'brand/getGmbCategory',
      getTouchpoints: 'brand/getTouchpoints',
      updateBrandLegacy: 'brand/updateBrandLegacy',
      updateTouchpoints: 'brand/updateTouchpoints',
      setUpdating: 'backoffice/setUpdating',
      setAlert: 'backoffice/setAlert',
    }),
    async manageGeneralMediaBeforeSave(payload) {
      const newLogo = await this.manageMedia({
        target: 'lp/brand/logo',
        media: this.currentBrand.logo,
        payloadNewMedia: payload.brandLogo,
        payloadMedia: payload.brand.logo,
        mediaName: this.currentBrand.name,
        mediaId: this.currentBrand.id,
      })
      const newFavicon = await this.manageMedia({
        target: 'lp/brand/favicon',
        media: this.currentBrand.favicon,
        payloadNewMedia: payload.brandFavicon,
        payloadMedia: payload.brand.favicon,
        mediaName: this.currentBrand.name,
        mediaId: this.currentBrand.id,
      })
      if (newLogo) {
        payload.brand.logo = newLogo
      }
      if (newFavicon) {
        payload.brand.favicon = newFavicon
      }
      this.saveBrand(payload)
    },
    async manageReviewsMediaBeforeSave(payload) {
      // Manage media for each lang
      const promisesBackground = Object.keys(payload.brandBackgroundImage).map(key =>
        this.manageMedia({
          target: 'lp/brand/background-picture',
          media: this.currentBrand.review_page?.background_picture[key],
          payloadNewMedia: payload.brandBackgroundImage[key],
          payloadMedia: payload.brand.review_page.background_picture[key],
          mediaName: `${key}-${this.currentBrand.name}`,
          mediaId: this.currentBrand.id,
        })
      )
      const mediaBackgroundUrls = await Promise.all(promisesBackground)
      Object.keys(payload.brandBackgroundImage).forEach((key, i) => {
        if (mediaBackgroundUrls[i]) {
          return (payload.brand.review_page.background_picture[key] = mediaBackgroundUrls[i])
        }
      })

      const promisesShopPicture = Object.keys(payload.locationLogo).map(key =>
        this.manageMedia({
          target: 'lp/location/logo',
          media: this.currentBrand.review_page?.default_shop_picture[key],
          payloadNewMedia: payload.locationLogo[key],
          payloadMedia: payload.brand.review_page.default_shop_picture[key],
          mediaName: `${key}-${this.currentBrand.name}`,
          mediaId: this.currentBrand.id,
        })
      )
      const mediaLogoUrls = await Promise.all(promisesShopPicture)
      Object.keys(payload.locationLogo).forEach((key, i) => {
        if (mediaLogoUrls[i]) {
          return (payload.brand.review_page.default_shop_picture[key] = mediaLogoUrls[i])
        }
      })

      this.saveBrand(payload)
    },
    async saveBrand(payload) {
      this.saving = true
      try {
        await this.updateBrandLegacy({ brand: payload.brand, brandId: payload.brand.id })
        this.showNotificationSuccess()
        if (this.currentTab === 'general') {
          this.$refs.brandGeneral.resetCurrentFile('brandLogo')
          this.$refs.brandGeneral.resetCurrentFile('brandFavicon')
        } else if (this.currentTab === 'reviews') {
          this.$refs.brandReviews.resetReviewsMedia()
        }
      } catch {
        this.showNotificationError()
      }
    },
    async saveTouchpoints(payload) {
      this.saving = true
      try {
        await this.updateTouchpoints({ brand_id: payload.brandId, settings: payload.brand })
        await this.getTouchpoints({ brandId: this.$route.params.id })
        this.showNotificationSuccess()
      } catch {
        this.showNotificationError()
      }
    },
    showNotificationError() {
      this.setAlert({
        color: 'error',
        text: this.$t('notification.error.default'),
      })
      this.saving = false
    },
    showNotificationSuccess() {
      this.setAlert({
        color: 'success',
        text: this.$t('notification.success.brand', { brand: this.currentBrand.name }),
      })
      this.saving = false
    },
  },
}
</script>
